// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start()
require('@rails/activestorage').start()
require('channels')

global.$ = require('jquery')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'bootstrap'
import '@hotwired/turbo-rails'
import 'chartkick/chart.js'
import debounced from 'debounced'
import './icons'
import 'controllers'
import 'views'
import CandidateEndorsementApp from './minis/candidate_endorsements'
import CandidateMarkdownApp from './minis/candidate_markdowns'
import CandidateQuestionnaireApp from './minis/candidate_questionnaire'
import FlashMessagesApp from './minis/flash'
import MeasureMarkdownApp from './minis/measure_markdowns'
import PositionMarkdownApp from './minis/position_markdowns'

// mini apps
window.CandidateEndorsementApp = CandidateEndorsementApp
window.CandidateMarkdownApp = CandidateMarkdownApp
window.CandidateQuestionnaireApp = CandidateQuestionnaireApp
window.FlashMessagesApp = FlashMessagesApp
window.MeasureMarkdownApp = MeasureMarkdownApp
window.PositionMarkdownApp = PositionMarkdownApp

document.addEventListener('turbo:load', () => {
  const tenantSwitcherEl = document.querySelector('.tenant-switcher')

  if (tenantSwitcherEl) {
    tenantSwitcherEl.addEventListener('change', (e) => e.target.form.submit())
  }
})

debounced.initialize({ change: { wait: 500 } })
