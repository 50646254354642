var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"card ce-card ce-card--editable\">\n    <div class=\"card-header align-items-center\">\n      "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":5,"column":6},"end":{"line":5,"column":15}}}) : helper)))
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEditing") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":7,"column":6},"end":{"line":15,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEditing") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":18,"column":4},"end":{"line":69,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "      <button class=\"btn btn-outline-light btn-sm ce-card__cancel-btn\" type=\"button\">\n        Cancel\n      </button>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "      <button class=\"btn btn-outline-light btn-sm ce-card__edit-btn\" type=\"button\">\n        Edit <i class=\"far fa-pencil ms-1\"></i>\n      </button>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"card-body\">\n      <form action=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"saveFormAction") || (depth0 != null ? lookupProperty(depth0,"saveFormAction") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"saveFormAction","hash":{},"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":20,"column":38}}}) : helper)))
    + "\" class=\"candidate-markdown__save-form\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"formId") || (depth0 != null ? lookupProperty(depth0,"formId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"formId","hash":{},"data":data,"loc":{"start":{"line":20,"column":82},"end":{"line":20,"column":92}}}) : helper)))
    + "\" method=\"post\">\n        <input type=\"hidden\" name=\"_method\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"saveFormMethod") || (depth0 != null ? lookupProperty(depth0,"saveFormMethod") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"saveFormMethod","hash":{},"data":data,"loc":{"start":{"line":21,"column":51},"end":{"line":21,"column":69}}}) : helper)))
    + "\" />\n        <input type=\"hidden\" name=\"markdown_type\" value=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"blob") : depth0)) != null ? lookupProperty(stack1,"markdown_type") : stack1), depth0))
    + "\" />\n        <input type=\"hidden\" name=\"candidate_id\" value=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"blob") : depth0)) != null ? lookupProperty(stack1,"candidate_id") : stack1), depth0))
    + "\" />\n        <input class=\"locale-input\" type=\"hidden\" name=\"locale\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"locale") || (depth0 != null ? lookupProperty(depth0,"locale") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":71},"end":{"line":24,"column":81}}}) : helper)))
    + "\" />\n        <div class=\"form-input\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isVideo") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":26,"column":10},"end":{"line":34,"column":17}}})) != null ? stack1 : "")
    + "        </div>\n      </form>\n    </div>\n    <div class=\"card-footer d-flex justify-content-end\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"blob") : depth0)) != null ? lookupProperty(stack1,"id") : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":6},"end":{"line":48,"column":13}}})) != null ? stack1 : "")
    + "\n      <button type=\"submit\" class=\"btn btn-primary ce-card__save-btn ms-auto\" form=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"formId") || (depth0 != null ? lookupProperty(depth0,"formId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"formId","hash":{},"data":data,"loc":{"start":{"line":50,"column":84},"end":{"line":50,"column":94}}}) : helper)))
    + "\">\n        Save\n      </button>\n    </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <input class=\"form-control\" type=\"text\" name=\"markdown_blob\" value=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"blob") : depth0)) != null ? lookupProperty(stack1,"markdown_blob") : stack1), depth0))
    + "\" />\n          <small class=\"form-text text-muted\">Input must be video url.</small>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isNewsLink") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":29,"column":10},"end":{"line":34,"column":10}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <input class=\"form-control\" type=\"text\" name=\"markdown_blob\" value=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"blob") : depth0)) != null ? lookupProperty(stack1,"markdown_blob") : stack1), depth0))
    + "\" />\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <textarea name=\"markdown_blob\" class=\"form-control textarea--markdown\"\n            placeholder=\"Add free-form content here\" rows=\"10\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"blob") : depth0)) != null ? lookupProperty(stack1,"markdown_blob") : stack1), depth0))
    + "</textarea>\n          ";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <form action=\"/candidate_markdowns/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"blob") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" class=\"candidate-markdown__delete-form\"\n        data-ceconfirm=\"Are you sure?\" method=\"post\">\n        <input type=\"hidden\" name=\"_method\" value=\"delete\" />\n        <input type=\"hidden\" name=\"candidate_id\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"blob") : depth0)) != null ? lookupProperty(stack1,"candidate_id") : stack1), depth0))
    + "\" />\n        <button class=\"btn btn-outline-danger ce-card__delete-btn\" type=\"submit\">\n          Delete <i class=\"fas fa-trash-alt ms-1\"></i>\n        </button>\n      </form>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isVideo") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":55,"column":4},"end":{"line":68,"column":11}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"card-body has-video\" data-video-url=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"blob") : depth0)) != null ? lookupProperty(stack1,"markdown_blob") : stack1), depth0))
    + "\"></div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isNewsLink") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":57,"column":4},"end":{"line":68,"column":4}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"card-body has-newslink\">\n      <img src=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"blob") : depth0)) != null ? lookupProperty(stack1,"metadata_blob") : stack1)) != null ? lookupProperty(stack1,"favicon") : stack1), depth0))
    + "\" />\n      <a href=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"blob") : depth0)) != null ? lookupProperty(stack1,"metadata_blob") : stack1)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\">\n        <h4>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"blob") : depth0)) != null ? lookupProperty(stack1,"metadata_blob") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\n      </a></h4>\n      <div>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"blob") : depth0)) != null ? lookupProperty(stack1,"metadata_blob") : stack1)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "</div>\n      <img src=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"blob") : depth0)) != null ? lookupProperty(stack1,"metadata_blob") : stack1)) != null ? lookupProperty(stack1,"image") : stack1), depth0))
    + "\" />\n    </div>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"card-body has-markdown\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"blob") : depth0)) != null ? lookupProperty(stack1,"markdown_blob") : stack1), depth0))
    + "</div>\n    ";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"card ce-card ce-card--blank\">\n    <div class=\"card-body\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isVideo") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":74,"column":6},"end":{"line":80,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n  </div>\n";
},"25":function(container,depth0,helpers,partials,data) {
    return "      <i class=\"far fa-file-video\"></i> Add audio/video\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isNewsLink") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(30, data, 0),"data":data,"loc":{"start":{"line":76,"column":6},"end":{"line":80,"column":6}}})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    return "      <i class=\"far fa-sticky-note\"></i> Add link to news site\n";
},"30":function(container,depth0,helpers,partials,data) {
    return "      <i class=\"far fa-sticky-note\"></i> Add free-form content\n      ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ce-card__container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"existsOrEditing") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(24, data, 0),"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":83,"column":9}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});