import EasyMDE from '@ludois/easymde'
import MarkdownIt from 'markdown-it'
import template from './measure_markdowns/app.handlebars'

export default () => {
  window.renderMeasureMarkdownTemplate = function (el, blob, options) {
    var saveFormAction =
      blob && blob.id ? `/measure_markdowns/${blob.id}` : '/measure_markdowns'

    el.innerHTML = template(
      Object.assign({}, options, {
        blob: blob,
        existsOrEditing: (blob && !blob.new_record) || options.isEditing,
        formId: `blobForm_${Math.random().toString(36).substring(7)}`,
        saveFormAction: saveFormAction,
        saveFormMethod: blob && blob.id ? 'patch' : 'post',
        locale: options.locale,
        measureId: options.measureId,
      }),
    )

    // Render Markdown
    el.querySelectorAll('.has-markdown').forEach(function (el) {
      const md = new MarkdownIt()
      el.innerHTML = md.render(el.innerHTML)
    })

    // Event handlers
    var cancelBtn = el.querySelector('.ce-card__cancel-btn')
    var deleteForm = el.querySelector('.measure-markdown__delete-form')
    var editBtn = el.querySelector('.ce-card--blank, .ce-card__edit-btn')
    var saveForm = el.querySelector('.measure-markdown__save-form')

    // Cancel button
    cancelBtn &&
      cancelBtn.addEventListener('click', function () {
        renderMeasureMarkdownTemplate(
          el,
          blob,
          Object.assign({}, options, {
            isEditing: false,
          }),
        )
      })

    // New & edit button
    editBtn &&
      editBtn.addEventListener('click', function () {
        renderMeasureMarkdownTemplate(
          el,
          blob,
          Object.assign({}, options, {
            isEditing: true,
          }),
        )

        el.querySelectorAll('.textarea--markdown').forEach(function (el) {
          new EasyMDE({
            element: el,
            forceSync: true,
          })
        })
      })

    // Save Form
    saveForm &&
      saveForm.addEventListener('submit', function (e) {
        var form = e.target

        e.preventDefault()

        options.onSave &&
          options.onSave(form).then(function (updatedBlob) {
            renderMeasureMarkdownTemplate(
              el,
              updatedBlob,
              Object.assign({}, options, {
                isEditing: false,
              }),
            )
          })
      })

    deleteForm &&
      deleteForm.addEventListener('submit', function (e) {
        var form = e.target
        var message = form.dataset.ceconfirm

        e.preventDefault()

        if (!confirm(message)) {
          e.stopPropagation()
          return false
        }

        options.onDelete &&
          options.onDelete(form).then(function () {
            var resetBlob = Object.assign({}, blob, {
              id: null,
              markdown_blob: null,
              new_record: true,
            })

            renderMeasureMarkdownTemplate(
              el,
              resetBlob,
              Object.assign({}, options, {
                isEditing: false,
              }),
            )
          })
      })
  }
}
