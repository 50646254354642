import { Controller } from '@hotwired/stimulus'

/** A controller which allows you create a set of radio toggles that looks like a list of bootstrap buttons.
 * See the endorsements/election_selection view for an example */
export default class extends Controller {
  static values = { selected: String, unselected: String }
  static targets = ['radio', 'submit']

  hover(event) {
    this.select(event.currentTarget)
  }

  unhover(event) {
    if (!event.currentTarget.querySelector('input').checked) {
      this.unselect(event.currentTarget)
    }
  }

  toggle() {
    this.hasSubmitTarget && (this.submitTarget.disabled = true)
    this.radioTargets.forEach((radio) => {
      const isChecked = radio.checked
      const label = radio.parentElement

      if (isChecked) {
        this.select(label)
        this.hasSubmitTarget && (this.submitTarget.disabled = false)
      } else {
        this.unselect(label)
      }
    })
  }

  select(label) {
    label.classList.add('btn-primary')
    label.classList.remove('btn-outline-secondary')
  }

  unselect(label) {
    label.classList.add('btn-outline-secondary')
    label.classList.remove('btn-primary')
  }
}
