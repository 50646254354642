import EasyMDE from '@ludois/easymde'
import MarkdownIt from 'markdown-it'
import template from './position_markdowns/app.handlebars'

export default () => {
  window.renderPositionMarkdownTemplate = function (el, blob, options) {
    var saveFormAction =
      blob && blob.id ? `/position_markdowns/${blob.id}` : '/position_markdowns'
    var type = el.dataset.type
    var title = type.charAt(0).toUpperCase() + type.slice(1)
    let propertyValue = null
    if (type === 'name' && options.name !== 'None') {
      propertyValue = options.name
    } else if (type === 'description' && options.description !== 'None') {
      propertyValue = options.description
    }
    let useMarkdown = true
    if (type === 'name') {
      useMarkdown = false
    }

    el.innerHTML = template(
      Object.assign({}, options, {
        blob: blob,
        existsOrEditing:
          (blob && !blob.new_record) || options.isEditing || propertyValue,
        formId: `blobForm_${Math.random().toString(36).substring(7)}`,
        saveFormAction: saveFormAction,
        saveFormMethod: blob && blob.id ? 'patch' : 'post',
        title: title,
        locale: options.locale,
        useMarkdown: useMarkdown,
        propertyValue: propertyValue,
      }),
    )

    el.querySelectorAll('.has-markdown').forEach(function (el) {
      const md = new MarkdownIt()
      el.innerHTML = md.render(el.innerHTML)
    })

    // Event handlers
    var cancelBtn = el.querySelector('.ce-card__cancel-btn')
    var deleteForm = el.querySelector('.position-markdown__delete-form')
    var editBtn = el.querySelector('.ce-card--blank, .ce-card__edit-btn')
    var saveForm = el.querySelector('.position-markdown__save-form')

    //cancel button
    cancelBtn &&
      cancelBtn.addEventListener('click', function () {
        renderPositionMarkdownTemplate(
          el,
          blob,
          Object.assign({}, options, {
            isEditing: false,
          }),
        )
      })

    //new and edit button
    editBtn &&
      editBtn.addEventListener('click', function () {
        renderPositionMarkdownTemplate(
          el,
          blob,
          Object.assign({}, options, {
            isEditing: true,
          }),
        )

        el.querySelectorAll('.textarea--markdown').forEach(function (el) {
          new EasyMDE({
            element: el,
            forceSync: true,
          })
        })
      })

    //save markdown
    saveForm &&
      saveForm.addEventListener('submit', function (e) {
        var form = e.target
        e.preventDefault()

        options.onSave &&
          options.onSave(form).then(function (updatedBlob) {
            renderPositionMarkdownTemplate(
              el,
              updatedBlob,
              Object.assign({}, options, {
                isEditing: false,
              }),
            )
          })
      })

    //delete markdown
    deleteForm &&
      deleteForm.addEventListener('submit', function (e) {
        var form = e.target
        var message = form.dataset.ceconfirm

        e.preventDefault()
        if (!confirm(message)) {
          e.stopPropagation()
          return false
        }

        options.onDelete &&
          options.onDelete(form).then(function () {
            var resetBlob = Object.assign({}, blob, {
              id: null,
              markdown_blob: null,
              new_record: true,
            })

            renderPositionMarkdownTemplate(
              el,
              resetBlob,
              Object.assign({}, options, {
                isEditing: false,
              }),
            )
          })
      })
  }
}
