import { Controller } from '@hotwired/stimulus'

const VALID_HEX = /^#([0-9a-fA-F]{6})$/

/** A controller for handling two connected elements, a color picker input and a text input for hex codes. */
export default class extends Controller {
  static targets = ['swatch', 'hex', 'error']

  static classes = ['visible', 'hidden']

  connect() {
    this.initializeColorPicker()
  }

  showError() {
    this.errorTarget.classList.add(this.visibleClass)
    this.errorTarget.classList.remove(this.hiddenClass)
  }

  hideError() {
    this.errorTarget.classList.remove(this.visibleClass)
    this.errorTarget.classList.add(this.hiddenClass)
  }

  initializeColorPicker() {
    this.swatchTarget.addEventListener('input', () => {
      this.hideError()
      this.hexTarget.value = this.swatchTarget.value
    })

    // Show error if input is not empty or valid hex
    this.hexTarget.addEventListener('input', () => {
      this.swatchTarget.value = this.hexTarget.value
      if (this.hexTarget.value && !VALID_HEX.test(this.hexTarget.value)) {
        this.showError()
      } else {
        this.hideError()
      }
    })
  }
}
