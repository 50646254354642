var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "      <button\n        type=\"button\"\n        class=\"btn btn-outline-light btn-sm ce-card__cancel-btn\"\n      >\n        Done\n      </button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "      <button\n        class=\"btn btn-outline-light btn-sm ce-card__edit-btn\"\n        type=\"button\"\n      >\n        Edit <i class=\"far fa-pencil ms-1\"></i>\n      </button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <tr>\n          <td colspan=\"2\">\n            <form\n              action=\"/endorsements\"\n              class=\"d-flex align-items-center justify-content-between candidate-endorsements__save-form\"\n              method=\"post\"\n            >\n              <input type=\"hidden\" name=\"_method\" value=\"post\" />\n              <input type=\"hidden\" name=\"endorsable_id\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"endorsableId") || (depth0 != null ? lookupProperty(depth0,"endorsableId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"endorsableId","hash":{},"data":data,"loc":{"start":{"line":32,"column":63},"end":{"line":32,"column":79}}}) : helper)))
    + "\">\n              <input type=\"hidden\" name=\"endorsable_type\" value=\"Candidacy\">\n              <select required class=\"form-select organization-search\" name=\"organization_id\">\n                <option></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"organizations") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":16},"end":{"line":38,"column":25}}})) != null ? stack1 : "")
    + "                </select>\n              <button type=\"submit\" class=\"btn btn-primary btn-sm ms-2\" title=\"Add\">\n                <i class=\"far fa-plus\"></i>\n              </button>\n            </form>\n          </td>\n        </tr>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":37,"column":33},"end":{"line":37,"column":39}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":37,"column":41},"end":{"line":37,"column":49}}}) : helper)))
    + "</option>\n";
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <tr>\n          <td>\n            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":50,"column":12},"end":{"line":50,"column":20}}}) : helper)))
    + "\n          </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"isEditing") : depths[1]),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":10},"end":{"line":66,"column":17}}})) != null ? stack1 : "")
    + "        </tr>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <td class=\"text-right\">\n              <form\n                action=\"/endorsements/"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data,"loc":{"start":{"line":55,"column":38},"end":{"line":55,"column":44}}}) : helper)))
    + "\"\n                method=\"post\"\n                class=\"candidate-endorsements__delete-form\"\n                data-ceconfirm=\"Are you sure?\"\n              >\n                <input type=\"hidden\" name=\"_method\" value=\"delete\" />\n                <button type=\"submit\" class=\"btn btn-sm btn-outline-danger ms-2\" title=\"Delete\">\n                  <i class=\"far fa-trash\"></i>\n                </button>\n              </form>\n            </td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"card ce-card ce-card--endorsements ce-card--editable\">\n  <div class=\"card-header align-items-center\">\n    Endorsements\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEditing") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":19,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n  <div class=\"card-body has-table\">\n    <table class=\"table\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEditing") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":6},"end":{"line":46,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"endorsements") : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":6},"end":{"line":68,"column":15}}})) != null ? stack1 : "")
    + "    </table>\n  </div>\n</div>\n";
},"useData":true,"useDepths":true});