import { Controller } from '@hotwired/stimulus'
import Tags from 'bootstrap5-tags'

export default class extends Controller {
  static values = {
    field: String,
    idField: String,
  }
  connect() {
    Tags.init(`#${this.fieldValue}`)
    if (this.hasIdFieldValue) {
      Tags.init(`#${this.idFieldValue}`)
    }
  }
}
