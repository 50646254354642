import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  // This controller allows you to have custom formatting for options in a select2 and to add a footer to a select2,
  // by putting the html for each option and the footer somewhere on the DOM and then telling this controller where to find them.
  // See _candidacy_selection.html.erb for an example.
  static values = {
    optionPrefix: String, // (optional) the prefix for the ids for the formatted elements. If the formatting for each element is in a div with ids like "person_1", set this to "person_"
    footer: String, // (optional) the id for the element of the footer
    noResultsMessage: String, // (optional) the id for the element of the no results message
    outsideApi: String, // (optional) the url for an outside api to get the options from
    additionalParams: Object, // (optional) params, in addition to search term, to add to the ajax call querystring
    disabled: Boolean, // (optional) whether the select2 is disabled
  }

  connect() {
    // Initialize Select2
    $(this.element).select2(this.settings())
    // select2 doesn't have a built-in footer option.
    // So, when there are results, the footer is a disabled result.
    // When there aren't results, the footer is appended to the "No results option"
    if (this.hasFooterValue) {
      this.addFooterToOptions()
    }
  }

  settings() {
    const settings = {
      templateResult: this.renderOption.bind(this), // format the results
      templateSelection(data) {
        // Add custom attributes to the <option> tag for the selected option
        // make sure that races search passes on related attributes
        if (data.researched !== undefined) {
          data.element.setAttribute('data-researched', data.researched)
          data.element.setAttribute('data-position', data.text)
          data.element.setAttribute('data-election', data.election_name)
        }
        return data.text
      },
      language: {
        noResults: this.renderNoResults.bind(this), // Set the display when there are no results
      },
      escapeMarkup(markup) {
        return markup // this allows the "no results" text to be markup by overriding the escapeMarkup function
      },
      disabled: this.hasDisabledValue && this.disabledValue,
    }

    // for searching races in the endorsements tool, we have to use an outside api.  This is the only place we use this right now so this is not very generalized.
    if (this.hasOutsideApiValue) {
      //pass additional search params that you might need for the API call
      const additionalParams = this.additionalParamsValue
      settings.ajax = {
        url: this.outsideApiValue,
        delay: 450,
        dataType: 'json',
        data(params) {
          const query = {
            term: params.term,
            ...additionalParams,
          }
          return query
        },
      }
      //want to make sure it doesn't search an empty string/make unnecessary calls. For now, search in the API is set so that it doesn't return results before 5 characters anyways.

      settings.minimumInputLength = 5
    }

    return settings
  }

  renderOption(data) {
    // if this option is the footer, render footer
    if (this.hasFooterValue && data.id === this.footerValue) {
      return this.renderFooterHtml()
    }

    // if no special formatting, just render text
    if (!data.id || !this.hasOptionPrefixValue) {
      return data.text
    }
    // special formatting for candidate results in candidacy dropdown
    if (data.type === 'candidate' && this.hasOutsideApiValue) {
      const container = document.createElement('div')
      container.classList.add(
        'select2-result-candidacy',
        'clearfix',
        'row',
        'm-0',
        'align-items-center',
      )

      container.innerHTML = `
        <div class='col col-1 me-4 px-0 select2-result-candidate__headshot'>
          ${data.image_html}
        </div>
        <div class='col p-0' style='font-size:0.75rem'>
          <div class='fw-bold select2-result-candidate__name'></div>
          <div class='select2-result-candidate__party'></div>
        </div>
      `

      container.querySelector('.select2-result-candidate__name').textContent =
        data.text
      container.querySelector('.select2-result-candidate__party').textContent =
        data.parties

      return container
    }
    // otherwise, find the formatting on the DOM and render a copy of it
    const optionDiv = document.getElementById(
      `${this.optionPrefixValue}${data.id}`,
    )

    return optionDiv.cloneNode(true)
  }

  renderNoResults() {
    const defaultMessage = '<div class="p-2">No results found</div>'
    // show custom no results message and custom footer
    if (this.hasNoResultsMessageValue && this.hasFooterValue) {
      return (
        this.renderNoResultsMessageHtml().outerHTML +
        this.renderFooterHtml().outerHTML
      )
      // show custom no results message without footer
    } else if (this.hasNoResultsMessageValue) {
      return this.renderNoResultsMessageHtml().outerHTML
    }
    // show default no results message plus footer
    else if (this.hasFooterValue) {
      return defaultMessage + this.renderFooterHtml().outerHTML
    }
    // show default no results message
    return defaultMessage
  }

  addFooterToOptions() {
    // append a disabled option with the footer value to the select2 dropdown
    const footerOption = new Option(
      this.footerValue,
      this.footerValue,
      false,
      false,
    )
    footerOption.disabled = true
    $(this.element).append(footerOption)
  }

  renderFooterHtml() {
    // return the HTML for the footer, as found in the dom using id footerValue
    const footerDiv = document.getElementById(this.footerValue)
    const footer = footerDiv.cloneNode(true)
    footer.disabled = true

    return footer
  }

  renderNoResultsMessageHtml() {
    // return the HTML for the no results message, as found in the dom using id noResultsMessageValue
    const noResultsMessageDiv = document.getElementById(
      this.noResultsMessageValue,
    )
    const noResultsMessage = noResultsMessageDiv.cloneNode(true)

    return noResultsMessage
  }
}
