import { Controller } from '@hotwired/stimulus'

/** A controller for hiding or showing a submit button based on whether the form is completed. */
export default class extends Controller {
  connect() {
    $(this.element).on('change', () => this.toggleSubmit())
    this.toggleSubmit()
  }

  toggleSubmit() {
    const form = this.element.form
    const submit = form.querySelector('button[type="submit"]')

    if (!this.element.disabled && form.checkValidity()) {
      submit.classList.add('fade', 'show')
    } else {
      submit.classList.add('fade')
      submit.classList.remove('show')
    }
  }
}
