import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['source']

  copyLink(e) {
    e.preventDefault()
    navigator.clipboard.writeText(this.sourceTarget.href).then(
      function () {
        /* clipboard successfully set */
      },
      function (e) {
        /* clipboard write failed */
        console.log('Copy failed with error:')
        console.log(e)
      },
    )
  }

  copyS3Instructions(e) {
    const button = e.currentTarget
    const bucketName = this.sourceTarget.value || 'AccountBucketName'
    const textToCopy = this.s3Instructions(bucketName)

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        button.textContent = 'Copied!'
        setTimeout(() => {
          button.textContent = 'Copy'
        }, 2000)
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err)
      })
  }

  s3Instructions(bucketName) {
    return `{
      "Version": "2012-10-17",
      "Statement": [{
        "Principal": {
         "AWS": "776114230139"
        },
        "Effect": "Allow",
        "Action": [
         "s3:GetObject",
         "s3:PutObject",
         "s3:PutObjectAcl",
         "s3:ListMultipartUploadParts",
         "s3:AbortMultipartUpload"
        ],
        "Resource": [
         "arn:aws:s3:::${bucketName}/*"
        ]
       },
       {
        "Principal": {
         "AWS": "776114230139"
        },
        "Effect": "Allow",
        "Action": [
         "s3:ListBucket",
         "s3:ListBucketMultipartUploads"
        ],
        "Resource": [
         "arn:aws:s3:::${bucketName}"
        ]
       }
      ]
     }`
  }
}
