var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"card ce-card ce-card--editable\">\n      <div class=\"card-header align-items-center\">\n        "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":17}}}) : helper)))
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEditing") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":21,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEditing") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":24,"column":6},"end":{"line":87,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "          <button\n            class=\"btn btn-outline-light btn-sm ce-card__cancel-btn\"\n            type=\"button\"\n          >\n            Cancel\n          </button>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "          <button\n            class=\"btn btn-outline-light btn-sm ce-card__edit-btn\"\n            type=\"button\"\n          >\n            Edit <i class=\"far fa-pencil ms-1\"></i>\n          </button>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"card-body\">\n          <form\n            action=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"saveFormAction") || (depth0 != null ? lookupProperty(depth0,"saveFormAction") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"saveFormAction","hash":{},"data":data,"loc":{"start":{"line":27,"column":20},"end":{"line":27,"column":38}}}) : helper)))
    + "\"\n            class=\"position-markdown__save-form\"\n            id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"formId") || (depth0 != null ? lookupProperty(depth0,"formId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"formId","hash":{},"data":data,"loc":{"start":{"line":29,"column":16},"end":{"line":29,"column":26}}}) : helper)))
    + "\"\n            method=\"post\"\n          >\n            <input type=\"hidden\" name=\"_method\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"saveFormMethod") || (depth0 != null ? lookupProperty(depth0,"saveFormMethod") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"saveFormMethod","hash":{},"data":data,"loc":{"start":{"line":32,"column":55},"end":{"line":32,"column":73}}}) : helper)))
    + "\" />\n            <input type=\"hidden\" name=\"markdown_type\" value=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"blob") : depth0)) != null ? lookupProperty(stack1,"markdown_type") : stack1), depth0))
    + "\" />\n            <input type=\"hidden\" name=\"position_id\" value=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"blob") : depth0)) != null ? lookupProperty(stack1,"position_id") : stack1), depth0))
    + "\"/>\n            <input class=\"locale-input\" type=\"hidden\" name=\"locale\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"locale") || (depth0 != null ? lookupProperty(depth0,"locale") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":35,"column":75},"end":{"line":35,"column":85}}}) : helper)))
    + "\"/>\n            <div class=\"form-input\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"useMarkdown") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":37,"column":14},"end":{"line":52,"column":21}}})) != null ? stack1 : "")
    + "            </div>\n          </form>\n        </div>\n        <div class=\"card-footer d-flex justify-content-end\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"blob") : depth0)) != null ? lookupProperty(stack1,"id") : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":10},"end":{"line":70,"column":17}}})) != null ? stack1 : "")
    + "\n          <button\n            type=\"submit\"\n            class=\"btn btn-primary ce-card__save-btn ms-auto\"\n            form=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"formId") || (depth0 != null ? lookupProperty(depth0,"formId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"formId","hash":{},"data":data,"loc":{"start":{"line":75,"column":18},"end":{"line":75,"column":28}}}) : helper)))
    + "\"\n          >\n            Save\n          </button>\n        </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <textarea\n                  name=\"markdown_blob\"\n                  class=\"form-control textarea--markdown\"\n                  placeholder=\"Add free-form content here\"\n                  rows=\"10\"\n                  >"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"blob") : depth0)) != null ? lookupProperty(stack1,"markdown_blob") : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":43,"column":19},"end":{"line":43,"column":99}}})) != null ? stack1 : "")
    + "</textarea>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"blob") : depth0)) != null ? lookupProperty(stack1,"markdown_blob") : stack1), depth0));
},"10":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"propertyValue") || (depth0 != null ? lookupProperty(depth0,"propertyValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"propertyValue","hash":{},"data":data,"loc":{"start":{"line":43,"column":75},"end":{"line":43,"column":92}}}) : helper)));
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <input\n                  type=\"text\"\n                  name=\"markdown_blob\"\n                  class=\"form-control\"\n                  placeholder=\"Add name here\"\n                  value=\""
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"blob") : depth0)) != null ? lookupProperty(stack1,"markdown_blob") : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":50,"column":25},"end":{"line":50,"column":105}}})) != null ? stack1 : "")
    + "\"\n                >\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <form\n              action=\"/position_markdowns/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"blob") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"\n              class=\"position-markdown__delete-form\"\n              data-ceconfirm=\"Are you sure?\"\n              method=\"post\"\n            >\n              <input type=\"hidden\" name=\"_method\" value=\"delete\" />\n              <input type=\"hidden\" name=\"position_id\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"blob") : depth0)) != null ? lookupProperty(stack1,"position_id") : stack1), depth0))
    + "\" />\n              <button class=\"btn btn-outline-danger ce-card__delete-btn\" type=\"submit\" >\n                Delete <i class=\"fas fa-trash-alt ms-1\"></i>\n              </button>\n            </form>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"blob") : depth0)) != null ? lookupProperty(stack1,"markdown_blob") : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":81,"column":8},"end":{"line":85,"column":15}}})) != null ? stack1 : "")
    + "\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"card-body "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"useMarkdown") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":32},"end":{"line":82,"column":70}}})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"blob") : depth0)) != null ? lookupProperty(stack1,"markdown_blob") : stack1), depth0))
    + "</div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "has-markdown";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"card-body "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"useMarkdown") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":84,"column":32},"end":{"line":84,"column":70}}})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"propertyValue") || (depth0 != null ? lookupProperty(depth0,"propertyValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"propertyValue","hash":{},"data":data,"loc":{"start":{"line":84,"column":72},"end":{"line":84,"column":89}}}) : helper)))
    + "</div>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"card ce-card ce-card--blank\">\n      <div class=\"card-body\">\n          <i class=\"far fa-sticky-note\"></i> Add "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data,"loc":{"start":{"line":92,"column":49},"end":{"line":92,"column":58}}}) : helper)))
    + "\n      </div>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ce-card__container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"existsOrEditing") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":95,"column":9}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});