import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

/**
 * Controller for dynamic forms or other situations in which
 * changing one select or select2 input, or another action, should update the contents of another input or other input.
 * For example, if selecting "country" should change your options for "state".
 * Can also be hooked up with another actions, such as the click of a button, to update the dynamicElement.
 */
export default class extends Controller {
  static targets = [
    'select2', // optional select2 element (you can also use a regular select with an action that calls handleChange)
    'dynamicElement', // element to be dynamically updated when select/select2 selection changes
  ]

  static values = {
    // route for getting data to populate dynamicElement. Controller should respond to turbo_stream to update the dynamicElement.
    url: String,
    // names (keys) of the parameter that the select/select2 selection will set with a value
    param: String,
    additionalParams: Array, // (optional) params, in addition to the initial one, that are set on change
  }

  connect() {
    // if the dynamic element doesn't have an id, give a unique one
    if (this.dynamicElementTarget.id === '') {
      this.dynamicElementTarget.id = Math.random().toString(36)
    }

    // since data-actions don't work on select2, give it an event listener
    if (this.hasSelect2Target) {
      $(this.select2Target).on('select2:select', (event) =>
        this.handleChange(event),
      )
    }
  }

  /** Using the value from the select and the id of the dynamicElement as params,
   * call the url with a turbo_stream responseKind */
  handleChange(event) {
    let params = new URLSearchParams()
    // if the element is a select or select2, use the selection as params.
    if (event.target.selectedOptions) {
      params.append(this.paramValue, event.target.selectedOptions[0].value)
      if (this.hasAdditionalParamsValue) {
        const additionalParams = this.additionalParamsValue
        for (const p of additionalParams) {
          params.append(
            p,
            event.target.selectedOptions[0].getAttribute(`data-${p}`),
          )
        }
      }
    }

    if (this.hasDynamicElementTarget) {
      params.append('target', this.dynamicElementTarget.id)
    }

    get(`${this.urlValue}?${params}`, {
      responseKind: 'turbo-stream',
    })
  }
}
