import { Controller } from '@hotwired/stimulus'
import FilterController from './filter_controller'

export default class extends FilterController {
  static targets = ['electionSelector']
  formChange = (event) => {
    // Submits form to /elections/by_state to populate elections list
    const form = event.target.form
    const action = form.action

    form.action = '/elections/by_state'
    form.requestSubmit()

    // reset form action (for eventual Next button click)
    form.action = action
  }
}
