import EasyMDE from '@ludois/easymde'
import renderReactPlayer from 'react-player/lib/standalone'
import MarkdownIt from 'markdown-it'
import template from './candidate_markdowns/app.handlebars'

export default () => {
  window.renderCandidateMarkdownTemplate = function (el, blob, options) {
    var saveFormAction =
      blob && blob.id
        ? `/candidate_markdowns/${blob.id}`
        : '/candidate_markdowns'
    var type = el.dataset.type
    var title = 'Free-form content'
    if (type === 'video') {
      title = 'Audio/Video'
    }
    if (type === 'news_link') {
      title = 'Link to news item'
    }

    el.innerHTML = template(
      Object.assign({}, options, {
        blob: blob,
        existsOrEditing: (blob && !blob.new_record) || options.isEditing,
        formId: `blobForm_${Math.random().toString(36).substring(7)}`,
        isVideo: type === 'video',
        isNewsLink: type === 'news_link',
        saveFormAction: saveFormAction,
        saveFormMethod: blob && blob.id ? 'patch' : 'post',
        title: title,
        locale: options.locale,
      }),
    )

    // Render Markdown
    el.querySelectorAll('.has-markdown').forEach(function (el) {
      const md = new MarkdownIt()
      el.innerHTML = md.render(el.innerHTML)
    })

    // Render video
    el.querySelectorAll('.has-video').forEach(function (el) {
      const url = el.dataset.videoUrl
      renderReactPlayer(el, {
        playing: false,
        url: url,
        width: '100%',
      })
    })

    // Event handlers
    var cancelBtn = el.querySelector('.ce-card__cancel-btn')
    var deleteForm = el.querySelector('.candidate-markdown__delete-form')
    var editBtns = el.querySelectorAll('.ce-card--blank, .ce-card__edit-btn')
    var saveForm = el.querySelector('.candidate-markdown__save-form')

    // Cancel button
    cancelBtn &&
      cancelBtn.addEventListener('click', function () {
        renderCandidateMarkdownTemplate(
          el,
          blob,
          Object.assign({}, options, {
            isEditing: false,
          }),
        )
      })

    // New & edit button
    editBtns &&
      editBtns.forEach(function (editBtn) {
        editBtn.addEventListener('click', function () {
          renderCandidateMarkdownTemplate(
            el,
            blob,
            Object.assign({}, options, {
              isEditing: true,
            }),
          )

          el.querySelectorAll('.textarea--markdown').forEach(function (el) {
            new EasyMDE({
              autoDownloadFontAwesome: false,
              element: el,
              forceSync: true,
            })
          })
        })
      })

    // Save Form
    saveForm &&
      saveForm.addEventListener('submit', function (e) {
        var form = e.target

        e.preventDefault()

        options.onSave &&
          options.onSave(form).then(function (updatedBlob) {
            renderCandidateMarkdownTemplate(
              el,
              updatedBlob,
              Object.assign({}, options, {
                isEditing: false,
              }),
            )
          })
      })

    deleteForm &&
      deleteForm.addEventListener('submit', function (e) {
        var form = e.target
        var message = form.dataset.ceconfirm

        e.preventDefault()

        if (!confirm(message)) {
          e.stopPropagation()
          return false
        }

        options.onDelete &&
          options.onDelete(form).then(function () {
            var resetBlob = Object.assign({}, blob, {
              id: null,
              markdown_blob: null,
              new_record: true,
            })

            renderCandidateMarkdownTemplate(
              el,
              resetBlob,
              Object.assign({}, options, {
                isEditing: false,
              }),
            )
          })
      })
  }
}
