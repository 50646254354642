import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'clearable', // (optional) select2 element to be cleared with "Clear All" button
  ]

  static values = {
    select2Selector: String, // (optional) selector for select2 elements
  }

  connect() {
    // this.select2SelectorValue
    $(this.select2SelectorValue).select2()
    $(this.select2SelectorValue).on('change', this.formChange)
  }

  setChildCheckboxes(children, checked) {
    children.forEach((child) => {
      child.checked = checked
    })

    // Not ideal but trigger the submit through the regular change event. Triggering only once
    // helps make this work with debouncing.
    const changeEvent = new Event('change')
    children[0].dispatchEvent(changeEvent)
  }

  selectAllTags() {
    event.preventDefault()

    const children =
      event.target.parentNode.parentNode.querySelectorAll('.tag_checkbox')
    const checked = true
    this.setChildCheckboxes(children, checked)
  }

  clearAllTags() {
    event.preventDefault()

    const children =
      event.target.parentNode.parentNode.querySelectorAll('.tag_checkbox')
    const checked = false
    this.setChildCheckboxes(children, checked)
  }

  toggleExpandCollapseIcon() {
    let targetIconClasses =
      event.currentTarget.getElementsByTagName('svg')[0].classList
    if (targetIconClasses.contains('fa-plus')) {
      targetIconClasses.replace('fa-plus', 'fa-minus')
    } else if (targetIconClasses.contains('fa-minus')) {
      targetIconClasses.replace('fa-minus', 'fa-plus')
    }
  }

  preventCloseDropdown(event) {
    event.preventDefault()

    // ignore the next close dropdown event
    document.addEventListener(
      'hide.bs.dropdown',
      (event) => {
        event.preventDefault()
      },
      { once: true },
    )
  }

  /**
   *  When using Turbo Stream, the browser url is not automatically
   *  updated with the new params. Use this to do so.
   * */
  updateURLFromForm(form) {
    // Get the form data and create query parameters
    const formData = new FormData(form)
    const params = new URLSearchParams(formData)

    // Update the browser URL with the query parameters
    const currentUrl = new URL(window.location.href)
    currentUrl.search = params.toString()

    // Replace the URL in the browser's history
    window.history.replaceState({}, '', currentUrl)
  }

  handleClearAllClick(event) {
    this.preventCloseDropdown(event)
    this.updateURLFromForm(event.target.form)
    this.clearableTargets.forEach((select2) =>
      $(select2).val(null).trigger('change'),
    )
  }

  formChange = (event) => {
    const form = event.target.form
    this.updateURLFromForm(form)
    form.requestSubmit()
  }

  /**
   *  Same as the using formChange, but gives the user
   *  time to finish typing.
   * */
  debounceFormChange = (event) => {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      if (this.hasActivityTarget && this.hasResultsTarget) {
        this.activityTarget.hidden = false
        this.resultsTarget.hidden = true
      }
      this.formChange(event)
    }, 500)
  }

  submitForm(event) {
    // Trigger the form submission event
    event.target.form.submit()
  }
}
