import { Controller } from '@hotwired/stimulus'

/** A controller setting the value of another field in the form based on the text of the select selection. */
export default class extends Controller {
  static targets = [
    'dynamicField', // element to be dynamically updated when select/select2 selection changes
    'select2', // optional select2 element (you can also use a regular select with an action that calls handleChange)
  ]

  connect() {
    // since data-actions don't work on select2, give it an event listener
    if (this.hasSelect2Target) {
      $(this.select2Target).on('change', (event) => this.handleChange(event))
    }
  }

  /** set value of otherField to text of select */
  handleChange(event) {
    this.dynamicFieldTarget.value = event.target.selectedOptions[0].text
  }
}
