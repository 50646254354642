import template from './candidate_questionnaire/app.handlebars'

export default () => {
  window.renderQuestionnaireTemplate = function (
    el,
    issue,
    candidateId,
    candidacyId,
    options,
  ) {
    var saveFormAction =
      issue && issue.stance_id ? `/stances/${issue.stance_id}` : '/stances'

    el.innerHTML = template(
      Object.assign(
        {},
        {
          candidateId: candidateId,
          candidacyId: candidacyId,
          existsOrEditing: (issue && issue.stance_id) || options.isEditing,
          formId: `issueForm_${issue.id}`,
          isEditing: options.isEditing,
          issue: issue,
          saveFormAction: saveFormAction,
          saveFormMethod: issue && issue.stance_id ? 'patch' : 'post',
          locale: options.locale,
        },
      ),
    )

    var editBtn = el.querySelector('.ce-card__edit-btn')
    var cancelBtn = el.querySelector('.ce-card__cancel-btn')
    var saveForm = el.querySelector('.questionnaire__save-form')
    var deleteForm = el.querySelector('.questionnaire__delete-form')

    var handleEditBtnClick = function () {
      renderQuestionnaireTemplate(
        el,
        issue,
        candidateId,
        candidacyId,
        Object.assign({}, options, {
          isEditing: true,
        }),
      )
    }

    var handleCancelBtnClick = function () {
      renderQuestionnaireTemplate(
        el,
        issue,
        candidateId,
        candidacyId,
        Object.assign({}, options, {
          isEditing: false,
        }),
      )
    }

    var handleFormSubmit = function (e) {
      var form = e.target
      e.preventDefault()

      options.onSave &&
        options.onSave(form).then(function (newStance) {
          ;(issue.description = newStance.description),
            (issue.stance_id = newStance.stance_id),
            (issue.candidate_id = newStance.candidate_id),
            renderQuestionnaireTemplate(
              el,
              issue,
              candidateId,
              candidacyId,
              Object.assign({}, options, {
                isEditing: false,
              }),
            )
        })
    }

    var handleDeleteSubmit = function (e) {
      var form = e.target
      var message = form.dataset.ceconfirm

      e.preventDefault()

      if (!confirm(message)) {
        e.stopPropagation()
        return false
      }

      options.onDelete &&
        options.onDelete(form).then(function () {
          issue.description = null
          issue.stance_id = null
          renderQuestionnaireTemplate(
            el,
            issue,
            candidateId,
            candidacyId,
            Object.assign({}, options, {
              isEditing: false,
            }),
          )
        })
    }

    editBtn && editBtn.addEventListener('click', handleEditBtnClick)
    cancelBtn && cancelBtn.addEventListener('click', handleCancelBtnClick)
    saveForm && saveForm.addEventListener('submit', handleFormSubmit)
    deleteForm && deleteForm.addEventListener('submit', handleDeleteSubmit)
  }
}
