import { Controller } from '@hotwired/stimulus'

/** Controller to remove everything inside of elements */
export default class extends Controller {
  static targets = [
    'erasableElement', // element that will have contents removed
  ]

  erase() {
    this.erasableElementTargets.forEach((e) => (e.innerHTML = ''))
  }
}
