import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['name', 'output']
  connect() {
    this.setOutput()
  }
  update() {
    this.setOutput()
  }

  setOutput() {
    this.outputTarget.textContent = `${
      window.LINK_BASE_URL
    }/${this.nameTarget.value
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^-|-$)/g, '')}`
  }
}
