import select2 from 'select2'
import template from './candidate_endorsements/app.handlebars'

export default () => {
  window.renderEndorsementTemplateCandidate = function (el, optionsData) {
    var filteredOrganizations = optionsData.organizations.filter(
      (organization) =>
        !optionsData.endorsements.findIndex(
          (endorsement) => endorsement.name === organization.name,
        ) !== -1,
    )

    el.innerHTML = template(
      Object.assign(
        {},
        {
          endorsements: optionsData.endorsements.sort(function (a, b) {
            var nameA = a.name.toUpperCase()
            var nameB = b.name.toUpperCase()
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }

            return 0
          }),
          organizations: filteredOrganizations,
          isEditing: optionsData.isEditing,
          endorsableId: optionsData.endorsableId,
          endorsableType: 'Candidacy',
        },
      ),
    )

    $('.organization-search').select2({
      tags: true,
      placeholder: 'Add Endorser',
      allowClear: true,
    })

    var editBtn = el.querySelector('.ce-card__edit-btn')
    var saveForm = el.querySelector('.candidate-endorsements__save-form')
    var deleteForms = el.querySelectorAll(
      '.candidate-endorsements__delete-form',
    )
    var cancelBtn = el.querySelector('.ce-card__cancel-btn')

    editBtn &&
      editBtn.addEventListener('click', function () {
        renderEndorsementTemplateCandidate(
          el,
          Object.assign({}, optionsData, { isEditing: true }),
        )
      })

    cancelBtn &&
      cancelBtn.addEventListener('click', function () {
        renderEndorsementTemplateCandidate(
          el,
          Object.assign({}, optionsData, { isEditing: false }),
        )
      })

    // Save Form
    saveForm &&
      saveForm.addEventListener('submit', function (e) {
        var form = e.target

        e.preventDefault()

        optionsData.onSave &&
          optionsData.onSave(form).then(function (endorsement) {
            var displayEndorsement = {
              name: endorsement.endorser,
              id: endorsement.id,
              organization_id: endorsement.organization_id,
            }
            optionsData.endorsements.push(displayEndorsement)
            renderEndorsementTemplateCandidate(el, optionsData)
          })
      })

    var handleDeleteFormSubmit = function (e) {
      var form = e.target
      var message = form.dataset.ceconfirm

      e.preventDefault()

      if (!confirm(message)) {
        e.stopPropagation()
        return false
      }

      optionsData.onDelete &&
        optionsData.onDelete(form).then(function (deletedEndorsement) {
          //remove it from the list of endorsements
          const index = optionsData.endorsements.findIndex(
            (endorsement) =>
              endorsement.id === deletedEndorsement.endorsement_id,
          )
          optionsData.endorsements.splice(index, 1)
          renderEndorsementTemplateCandidate(el, optionsData)
        })
    }

    deleteForms.forEach(function (formElement) {
      formElement.addEventListener('submit', handleDeleteFormSubmit)
    })
  }
}
