import template from './flash/app.handlebars'

export default () => {
  window.renderFlashMessage = function (el, type, message) {
    el.innerHTML = template(
      Object.assign(
        {},
        {
          type: type,
          message: message,
        },
      ),
    )
  }
}
