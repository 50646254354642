import { config, library, dom } from '@fortawesome/fontawesome-svg-core'
import {
  faFacebookF,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'

import { faToggleOff, faToggleOn } from '@fortawesome/pro-light-svg-icons'

import {
  faAngleRight,
  faArrowsAlt,
  faArrowFromRight,
  faAward,
  faBallotCheck,
  faBold,
  faBrowser,
  faCog,
  faColumns,
  faExternalLink,
  faGlobeAmericas,
  faEye,
  faFileExport,
  faFileVideo,
  faFilter,
  faFlagAlt,
  faGlobe,
  faHeading,
  faImage,
  faInfoCircle,
  faItalic,
  faLandmarkAlt,
  faLanguage,
  faLink,
  faListOl,
  faListUl,
  faLock,
  faMapMarkerAlt,
  faMinus,
  faPencil,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faQuoteLeft,
  faSearch,
  faSpinner,
  faStar,
  faStickyNote,
  faTag,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrash,
  faUser,
  faUsers,
  faChartLine,
  faCheck,
  faCheckCircle,
  faExclamationTriangle,
} from '@fortawesome/pro-regular-svg-icons'

library.add(
  faAngleRight,
  faArrowsAlt,
  faArrowFromRight,
  faAward,
  faBallotCheck,
  faBold,
  faBrowser,
  faCog,
  faColumns,
  faGlobeAmericas,
  faExternalLink,
  faEye,
  faFacebookF,
  faFilter,
  faFileExport,
  faFileVideo,
  faFlagAlt,
  faGlobe,
  faHeading,
  faImage,
  faInfoCircle,
  faInstagram,
  faItalic,
  faLandmarkAlt,
  faLanguage,
  faLink,
  faListOl,
  faListUl,
  faLock,
  faMapMarkerAlt,
  faMinus,
  faPencil,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faQuoteLeft,
  faSearch,
  faSpinner,
  faStar,
  faStickyNote,
  faTag,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faToggleOn,
  faToggleOff,
  faTrash,
  faTwitter,
  faUser,
  faUsers,
  faChartLine,
  faCheck,
  faCheckCircle,
  faExclamationTriangle,
)

dom.watch()
