import { Controller } from '@hotwired/stimulus'

/** Controller to remove element */
export default class extends Controller {
  static targets = [
    'disableEnable', // element that will be enabled or disabled
  ]

  enableElement() {
    this.disableEnableTarget.disabled = false
  }

  disableElement() {
    this.disableEnableTarget.disabled = true
  }
}
