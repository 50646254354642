import { Controller } from '@hotwired/stimulus'

const RADIO_SELECTION_TOGGLE = 'radio-selection-toggle'

export default class extends Controller {
  static targets = ['details', 'input']

  connect() {
    document.addEventListener(
      RADIO_SELECTION_TOGGLE,
      this.handleSelection.bind(this),
    )
  }

  toggle(event) {
    event.stopImmediatePropagation()

    const selectionEvent = new CustomEvent(RADIO_SELECTION_TOGGLE, {
      detail: {
        name: event.target.name,
        value: event.target.value,
      },
    })

    document.dispatchEvent(selectionEvent)

    return false
  }

  handleSelection(event) {
    if (event.detail.name === this.inputTarget.name) {
      this.detailsTarget.hidden = event.detail.value !== this.inputTarget.value
    }
  }
}
