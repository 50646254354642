var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"existsOrEditing") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":87,"column":9}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"card ce-card ce-card--editable\">\n      <div class=\"card-header align-items-center py-1\">\n        Answer\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEditing") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":24,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEditing") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":27,"column":6},"end":{"line":78,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "          <button\n            class=\"btn btn-outline-light btn-sm ce-card__cancel-btn\"\n            type=\"button\"\n          >\n            Cancel\n          </button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "          <button\n            class=\"btn btn-text text-white btn-sm ce-card__edit-btn\"\n            title=\"Edit\"\n            type=\"button\"\n          >\n            <i class=\"far fa-pencil ms-1\"></i>\n          </button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"card-body\">\n          <form\n            action=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"saveFormAction") || (depth0 != null ? lookupProperty(depth0,"saveFormAction") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"saveFormAction","hash":{},"data":data,"loc":{"start":{"line":30,"column":20},"end":{"line":30,"column":38}}}) : helper)))
    + "\"\n            id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"formId") || (depth0 != null ? lookupProperty(depth0,"formId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"formId","hash":{},"data":data,"loc":{"start":{"line":31,"column":16},"end":{"line":31,"column":26}}}) : helper)))
    + "\"\n            method=\"post\"\n            class=\"questionnaire__save-form\"\n          >\n            <input type=\"hidden\" name=\"_method\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"saveFormMethod") || (depth0 != null ? lookupProperty(depth0,"saveFormMethod") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"saveFormMethod","hash":{},"data":data,"loc":{"start":{"line":35,"column":55},"end":{"line":35,"column":73}}}) : helper)))
    + "\" />\n            <textarea\n              class=\"form-control\"\n              rows=\"5\"\n              name=\"description\"\n              placeholder=\"Add response\"\n            >"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"issue") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "</textarea>\n            <input type=\"hidden\" name=\"issue_id\" value=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"issue") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n            <input type=\"hidden\" name=\"candidate_id\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"candidateId") || (depth0 != null ? lookupProperty(depth0,"candidateId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"candidateId","hash":{},"data":data,"loc":{"start":{"line":43,"column":60},"end":{"line":43,"column":75}}}) : helper)))
    + "\">\n            <input type=\"hidden\" name=\"locale\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"locale") || (depth0 != null ? lookupProperty(depth0,"locale") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":44,"column":54},"end":{"line":44,"column":64}}}) : helper)))
    + "\">\n            <input type=\"hidden\" name=\"candidacy_id\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"candidacyId") || (depth0 != null ? lookupProperty(depth0,"candidacyId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"candidacyId","hash":{},"data":data,"loc":{"start":{"line":45,"column":60},"end":{"line":45,"column":75}}}) : helper)))
    + "\">\n          </form>\n        </div>\n\n        <div class=\"card-footer d-flex justify-content-end\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"issue") : depth0)) != null ? lookupProperty(stack1,"stance_id") : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":10},"end":{"line":66,"column":17}}})) != null ? stack1 : "")
    + "\n          <button\n            type=\"submit\"\n            class=\"btn btn-primary ce-card__save-btn ms-auto\"\n            form=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"formId") || (depth0 != null ? lookupProperty(depth0,"formId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"formId","hash":{},"data":data,"loc":{"start":{"line":71,"column":18},"end":{"line":71,"column":28}}}) : helper)))
    + "\"\n          >\n            Save\n          </button>\n        </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <form\n              action=\"/stances/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"issue") : depth0)) != null ? lookupProperty(stack1,"stance_id") : stack1), depth0))
    + "\"\n              class=\"questionnaire__delete-form\"\n              data-ceconfirm=\"Are you sure?\"\n              method=\"post\"\n            >\n              <input type=\"hidden\" name=\"_method\" value=\"delete\" />\n              <input type=\"hidden\" name=\"issue_id\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"issue") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n              <button\n                class=\"btn btn-outline-danger ce-card__delete-btn\"\n                type=\"submit\"\n              >\n                Delete <i class=\"fas fa-trash-alt ms-1\"></i>\n              </button>\n            </form>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"card-body\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"issue") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "</div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"card ce-card ce-card--blank ce-card__edit-btn\">\n      <div class=\"card-body d-flex py-2 align-items-center justify-content-between\">\n        Add answer\n        <i class=\"far fa-pencil\"></i>\n      </div>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h4>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"issue") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</h4>\n<p class=\"question\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"issue") : depth0)) != null ? lookupProperty(stack1,"question_text") : stack1), depth0))
    + "</p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"candidacyId") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":88,"column":7}}})) != null ? stack1 : "");
},"useData":true});